import React, {
  startTransition,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import LayoutComponent from "../../components/shared/Layout";
import { Button, Container } from "react-bootstrap";
import Info from "../../components/Info";
import FancyBet from "../../components/FancyBet";
import Bookmaker from "../../components/Bookmaker";
import MarketDepth from "../../components/MarketDepth";
import MyBets from "../../components/MyBets";
import { useLocation, useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { add, inRange, isEmpty, isNumber, subtract } from "lodash";
import { apiGet } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import { categoryArray, priceFormat } from "../../Utils/constants";
import Runner from "./Runner";
import { io } from "socket.io-client";
import BetSlipContext from "../../context/BetSlipContext";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import { useScrollPosition } from "react-use-scroll-position";
import BottomNavigation from "../../components/BottomNavigation";
import RunnerNew from "./RunnerNew";
import Lottery from "../Lottery/Lottery";
import BattingIframe from "../../components/BattingIframe";
import MyBetsNew from "../../components/MyBetsNew";
const FullMarket = () => {
  const parmas = useParams();
  const {
    user,
    profileData,
    setShowPosition,
    setActiveWidget,
    activeWidget,
    setSocketId,
  } = useContext(AuthContext);
  const { y } = useScrollPosition();
  const [fancySportTab, setFancySportTab] = useState("lottery");
  const [showInfo, setShowInfo] = useState(false);
  const [sportRules, setSportRules] = useState(false);
  const [showMarketDepthPopup, setShowMarketDepthPopup] = useState(false);
  const [showMyBet, setShowMyBet] = useState(false);
  const [showMyBet2, setShowMyBet2] = useState(false);
  const [detail, setDetail] = useState({});
  const [fancyCategoryArray, setFancyCategoryArray] = useState([]);
  const [runner, setRunner] = useState([]);
  const [bookmaker, setBookmaker] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [fancyHeader, setFancyHeader] = useState([]);
  const [checkTvRefresh, setTvCheckRefresh] = useState(true);
  const [lineMarket, setLineMarket] = useState([]);
  const {
    setBetSlipObject,
    setShowBetDialog,
    betSlipObject,
    showBetDialog,
    resetBetSlipObject,
    resetBetPosition,
    setResetBetPosition,
    betPlace,
    setLimit,
    setBetLoader,
    setBetPlace,
  } = useContext(BetSlipContext);
  const [score, setScore] = useState("");
  const [scrollHeight, setScrollHeight] = useState(75);
  const [marketName, setMarketName] = useState("Match Odds");
  const [runnerBackOdds, setRunnerBackOdds] = useState([]);
  const [runnerLayOdds, setRunnerLayOdds] = useState([]);
  const [bookmakerBackOdds, setBookmakerBackOdds] = useState([]);
  const [bookmakerLayOdds, setBookmakerLayOdds] = useState([]);
  const [fancyHeaderKey, setFancyHeaderKey] = useState("All");
  const [fancyUpdated, setFancyUpdate] = useState([]);
  const [position, setPosition] = useState([]);
  const [fancyPosition, setFancyPosition] = useState([]);
  const [totalMatched, setTotalMatched] = useState(0);
  const [tieData, setTieData] = useState([]);
  const [tossData, setTossData] = useState([]);
  const [back_oddsTie, setBackOddsTie] = useState([]);
  const [lay_oddsTie, setLayOddsTie] = useState([]);
  const [bookmakerSuspended, setBookmakerSuspended] = useState(true);
  const [betFairMs, setBetFairMs] = useState(1);
  const [widgetsData, setWidgetsData] = useState([]);
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [marketNew, setMarketNew] = useState([]);
  const [marketId, setMarketId] = useState([]);
  const [multiPosition, setMultiPosition] = useState([]);
  const ref = useRef();
  const [controls, setControls] = useState(false);
  const [topTab, setTopTab] = useState("scoreboard");
  const [tvClass, setTvClass] = useState(false);

  const handleShowBet = () => {
    setShowMyBet(!showMyBet);
  };
  const handleShowBet2 = () => {
    setShowMyBet2(!showMyBet2);
  };

  const handleShowInfo = () => {
    setShowInfo(!showInfo);
    if (fancySportTab === "sport") {
      setSportRules(!sportRules);
    }
  };

  const getMatchDetails = async (info = true) => {
    if (info) {
      document.getElementById("loading").style.display = "flex";
    } 
    let obj = !isEmpty(user)
      ? { userId: user?.user?._id, eventId: parmas?.eventId }
      : { eventId: parmas?.eventId };
    const { status, data: response_users } = await apiGet(
      apiPath.matchDetail,
      obj
    );
    if (status === 200) {
      if (response_users?.success) {
        let filteredArray = categoryArray?.filter((f) =>
          response_users?.results?.fancyList?.some(
            (item) => item?.categoryType === f.type
          )
        );
        setFancyCategoryArray(
          filteredArray?.length > 0
            ? [
                { type: "All", name: "All" },
                ...filteredArray,
                { type: "More", name: "More" },
              ]
            : [{ type: "All", name: "All" }]
        );
        if (response_users?.results?.marketList?.length > 1) {
          if (response_users?.results?.gameType == "cricket") {
            let latest = response_users?.results?.marketList?.filter((res) => {
              return res?.jsonData?.length > 0;
            });
            setMarketNew(latest || []);
            let updated = latest?.map((res) => {
              return res?.marketId;
            });
            if (updated?.length > 0) {
              setMarketId(updated);
            }
            let filteredArray = latest?.filter((res) => {
              return res?.marketId == parmas?.marketId;
            });

            setRunner(filteredArray[0]?.jsonData || []);
            setMarketName(filteredArray[0]?.market);
          } else if (response_users?.results?.gameType == "soccer") {
            let latest = response_users?.results?.marketList?.filter((res) => {
              return res?.market !== "Match Odds" && res?.jsonData?.length > 0;
            });
            setMarketNew(latest || []);
            let updated = latest?.map((res) => {
              return res?.marketId;
            });
            if (updated?.length > 0) {
              setMarketId(updated);
            }
            let filteredArray = latest?.filter((res) => {
              return res?.marketId == parmas?.marketId;
            });
            setRunner(filteredArray[0]?.jsonData || []);
            setMarketName(filteredArray[0]?.market);
          } else {
            setMarketName("Match Odds");
            setRunner(response_users?.results?.jsonData || []);
          }
        } else {
          setMarketName("Match Odds");
          setRunner(response_users?.results?.jsonData || []);
        }

        if (response_users?.results?.jsonData?.length == 3) {
          setTossData(response_users?.results?.jsonData.slice(0, 2) || []);
        } else {
          setTossData(response_users?.results?.jsonData || []);
        }

        if (response_users?.results?.gameType == "soccer") {
          setBookmaker(response_users?.results?.jsonBookmakerData || []);
        } else {
          setBookmaker(response_users?.results?.jsonData || []);
        }
        setDetail(response_users?.results);
        setTieData(response_users?.results?.jsonTieData || []);
        getWidgets(response_users?.results);
      }
    }
    document.getElementById("loading").style.display = "none";
  };

  const getWidgets = async (detail) => {
    const { status, data: response_users } = await apiGet(apiPath.matchList, {
      seriesId: detail?.seriesId,
    });
    if (status === 200) {
      if (response_users?.success) {
        if (response_users?.results?.length > 0) {
          if (isEmpty(activeWidget)) {
            setActiveWidget(detail?._id);
          }
          setWidgetsData(response_users?.results);
        } else {
          setWidgetsData([]);
          setActiveWidget("");
        }
      }
    }
  };

  console.log(showMyBet2, "showMyBet2");
  const getMatchOdds = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${!isEmpty(id) ? id : parmas.marketId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        if (
          socket_data &&
          socket_data?.eventId &&
          parmas?.eventId == socket_data?.eventId
        ) {
          if (socket_data.ms) {
            setBetFairMs(socket_data.ms);
          }
          if (socket_data.rt?.length) {
            const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
            const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
            setRunnerBackOdds(back_odds);
            setRunnerLayOdds(lay_odds);
          }
        }
        if (!isEmpty(id)) {
          setCheckRefresh(true);
        }
      }
    }
  };

  const getBookmakerOdds = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.bookmakerOdds + `?eventId=${parmas.eventId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const message = response_users?.results;
        if (message.eventId === parmas?.eventId) {
          const back_odds = message?.rt?.filter((rt) => rt.ib) || [];
          const newLayOdds = message?.rt?.filter((rt) => !rt.ib) || [];
          let lay_odds = [];
          if (back_odds?.length > 0 && newLayOdds?.length > 0) {
            lay_odds = back_odds?.map((res) => {
              let obj = newLayOdds?.find((item) => {
                return item?.runnerName == res?.runnerName;
              });
              if (obj?.runnerName == res?.runnerName && obj?.rt == res?.rt) {
                return obj;
              } else {
                return {
                  ...res,
                  ib: false,
                  rt: checkLayOdds(Number(res?.rt)),
                };
              }
            });
          } else {
            lay_odds = newLayOdds;
          }
          setBookmakerLayOdds(lay_odds);
          setBookmakerBackOdds(back_odds);
          if (message?.rt?.length == 0) {
            setBookmakerSuspended(true);
          } else {
            setBookmakerSuspended(false);
          }
        }
      } else {
        setBookmakerSuspended(true);
      }
    }
  };

  const getMatchOddsMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds +
        `?marketId=${id?.join(",")}&multi=true&eventId=${parmas?.eventId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setMarketNew((prev) => {
          return prev?.map((res) => {
            let temp = socket_data?.find((temp) => {
              return temp?.mi == res?.marketId;
            });
            if (temp?.mi == res?.marketId) {
              console.log(temp, "temp");
              return {
                ...res,
                odds: {
                  mi: temp?.mi,
                  ms: temp?.ms,
                  totalMatched: temp?.totalMatched,
                  ts: temp?.ts,
                  rt: temp?.rt,
                },
              };
            } else {
              return res;
            }
          });
        });
      }
    }
  };

  const getBetPositionMulti = async (id) => {
    if (secureLocalStorage.getItem("betFairMulti")?.length > 0) {
      updatePositionMulti(secureLocalStorage.getItem("betFairMulti"));
    } else {
      const { status, data: response_users } = await apiGet(
        apiPath.betPosition +
          `?eventId=${parmas.eventId}&type=${
            detail?.gameType
          }&marketId=${id?.join(",")}&multi=true`
      );
      if (status === 200) {
        if (response_users.success) {
          // if (response_users?.results?.betFair?.length > 0) {

          // }
          secureLocalStorage.setItem(
            "betFairMulti",
            response_users?.results?.betFair
          );
          updatePositionMulti(response_users?.results?.betFair);
        }
      }
    }
  };

  const updatePositionMulti = (object) => {
    setMultiPosition(object);
    setMarketNew((current) =>
      object?.length > 0
        ? current?.map((res) => {
            return {
              ...res,
              jsonData: res?.jsonData?.map((newObj) => {
                let obj = object?.find(
                  (item) => item?.selectionId == newObj?.SelectionId
                );
                if (!isEmpty(obj)) {
                  return {
                    ...newObj,
                    position:
                      obj?.selectionId == newObj?.SelectionId
                        ? obj?.position
                        : 0,
                    newPosition: 0,
                  };
                } else {
                  return {
                    ...newObj,
                    position: 0,
                    newPosition: 0,
                  };
                }
              }),
            };
          })
        : current
    );
  };

  const getBetPosition = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?eventId=${parmas.eventId}&type=${detail?.gameType}&marketId=${
          !isEmpty(id) ? id : parmas?.marketId
        }`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users?.results?.betFair?.length > 0) {
          secureLocalStorage.setItem(
            "betFair",
            response_users?.results?.betFair
          );
        }
        if (response_users?.results?.bookmaker?.length > 0) {
          secureLocalStorage.setItem(
            "bookmaker",
            response_users?.results?.bookmaker
          );
        }
        if (response_users?.results?.tie?.length > 0) {
          secureLocalStorage.setItem("tie", response_users?.results?.tie);
        }
        if (response_users?.results?.toss?.length > 0) {
          secureLocalStorage.setItem("toss", response_users?.results?.toss);
        }
        updatePosition(response_users?.results);
      }
    }
  };

  const updatePosition = (object) => {
    setPosition(object);
    setRunner((current) =>
      object?.betFair?.length > 0
        ? current.map((res) => {
            let obj = object?.betFair.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            if (!isEmpty(obj)) {
              return {
                ...res,
                position:
                  obj?.selectionId == res?.SelectionId ? obj?.position : 0,
                newPosition: 0,
              };
            } else {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            }
          })
        : current.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    setBookmaker((current) =>
      object?.bookmaker?.length > 0
        ? current?.map((res) => {
            let obj = object?.bookmaker?.find(
              (item) => item?.selectionId == `B${res?.SelectionId}`
            );
            return {
              ...res,
              position: obj?.position ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    setTieData((current) =>
      object?.tie?.length > 0
        ? current?.map((res) => {
            let obj = object?.tie?.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            return {
              ...res,
              position:
                obj?.selectionId == res?.SelectionId ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    setTossData((current) =>
      object?.toss?.length > 0
        ? current?.map((res) => {
            let obj = object?.toss?.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            return {
              ...res,
              position:
                obj?.selectionId == res?.SelectionId ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
  };

  const updatePositionLine = (object) => {
    object = object?.map((res) => {
      return {
        ...res,
        ruunerArray: res?.ruunerArray?.map((item) => {
          return {
            ...item,
            position:
              Math.abs(item?.newPosition) > 0
                ? item?.newPosition
                : item?.position,
            newPosition: 0,
          };
        }),
      };
    });
    setLineMarket((current) => {
      return object?.length > 0
        ? current?.map((res) => {
            let resObject = object?.find((item) => {
              return item?.name == res?.name;
            });
            if (!isEmpty(resObject)) {
              return { ...res, ruunerArray: resObject?.ruunerArray };
            } else {
              return res;
            }
          })
        : current?.map((res) => {
            return {
              ...res,
              ruunerArray: res?.ruunerArray?.map((item) => {
                return { ...item, position: 0, newPosition: 0 };
              }),
            };
          });
    });
  };

  const getFancyBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.betFancyPosition +
        `?eventId=${parmas.eventId}&type=${detail.gameType}`
    );
    if (status === 200) {
      if (response_users.success) {
        secureLocalStorage.setItem(
          "fancy",
          response_users?.results?.map((res) => {
            return { selectionId: res?.selectionId, position: res?.position };
          })
        );
        updatedFancyPosition(
          response_users?.results?.map((res) => {
            return { selectionId: res?.selectionId, position: res?.position };
          })
        );
      }
    }
  };

  const updatedFancyPosition = (object) => {
    setFancyPosition(object);
    setFancy((prev) => {
      return prev?.map((res) => {
        let obj = object?.find((item) => item.selectionId == res.selectionId);
        return {
          ...res,
          odds: res,
          position: !isEmpty(obj) ? obj?.position : "",
        };
      });
    });
    setFancyUpdate((prev) => {
      return prev?.map((res) => {
        let obj = object?.find((item) => item.selectionId == res.selectionId);
        return {
          ...res,
          odds: res,
          position: !isEmpty(obj) ? obj?.position : "",
        };
      });
    });
  };

  useEffect(() => {
    if (marketId?.length > 0) {
      let interval = setInterval(() => {
        getMatchOddsMulti(marketId);
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [marketId]);

  useEffect(() => {
    if (marketId?.length > 0) {
      setTimeout(() => {
        getBetPositionMulti(marketId);
      }, 3000);
    }
  }, [marketId]);

  useEffect(() => {
    if (!isEmpty(user)) {
      let interval = setInterval(() => {
        getMatchDetails(false);
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [user]);

  const handelChangeFancyHeader = (id) => {
    if (id == "All") {
      setFancyUpdate(fancy);
    } else if (id == "More") {
      let arr = fancy?.filter(
        (res) =>
          res?.categoryType !== 1 &&
          res?.categoryType !== 2 &&
          res?.categoryType !== 3 &&
          res?.categoryType !== 4 &&
          res?.categoryType !== 5 &&
          res?.categoryType !== 6 &&
          res?.categoryType !== 7 &&
          res?.categoryType !== 8 &&
          res?.categoryType !== 9 &&
          res?.categoryType !== 10 &&
          res?.categoryType !== 11 &&
          res?.categoryType !== 14
      );
      setFancyUpdate(arr);
    } else {
      let temp = fancy?.filter((res) => {
        return res?.categoryType == id;
      });
      setFancyUpdate(temp);
    }
    setFancyHeaderKey(id);
  };

  const listenBetfairOdds = (event) => {
    const socket_data =
      (event &&
        event?.results?.find((item) => item.eventId == parmas?.eventId)) ||
      [];
    if (
      socket_data &&
      socket_data?.eventId &&
      parmas?.eventId == socket_data?.eventId &&
      parmas?.marketId == socket_data?.mi
    ) {
      if (socket_data.ms) {
        setBetFairMs(socket_data.ms);
      }
      if (socket_data.rt?.length) {
        const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
        const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
        let newArray = [...back_odds].concat([...lay_odds]);
        setBetLoader((loaderPrev) => {
          if (!loaderPrev) {
            setBetSlipObject((prev) => {
              if (prev?.playType == "betFair") {
                if (newArray?.length > 0) {
                  let updated = newArray?.find((res) => {
                    return (
                      res?.ib == prev?.object?.ib &&
                      res?.ri == prev?.object?.SelectionId
                    );
                  });
                  handelBetFairPositions({
                    ...prev,
                    odds: updated?.rt,
                  });
                  return {
                    ...prev,
                    odds: updated?.rt,
                  };
                } else {
                  resetPosition("betFair");
                  setShowBetDialog(false);
                  return {
                    odds: "",
                    bidPrice: 0,
                    teamName: "",
                    betType: "",
                  };
                }
              } else {
                return prev;
              }
            });
          }
          return loaderPrev;
        });

        setRunnerBackOdds(back_odds);
        setRunnerLayOdds(lay_odds);
        setTotalMatched(socket_data?.totalMatched);
      }
    }
  };

  const checkLayOdds = (rt) => {
    if (inRange(rt, 0, 10)) {
      return Number(rt + 0.5);
    } else if (inRange(rt, 10, 30)) {
      return Number(rt + 1);
    } else if (inRange(rt, 30, 50)) {
      return Number(rt + 1.5);
    } else if (inRange(rt, 50, 99)) {
      return Number(rt + 2);
    } else {
      return rt;
    }
  };

  const listenBookmakerData = (message) => {
    if (message.eventId === parmas?.eventId) {
      const back_odds = message?.results?.rt?.filter((rt) => rt.ib) || [];
      const newLayOdds = message?.results?.rt?.filter((rt) => !rt.ib) || [];
      let lay_odds = newLayOdds;
      // if (back_odds?.length > 0 && newLayOdds?.length > 0) {
      //   lay_odds = back_odds?.map((res) => {
      //     let obj = newLayOdds?.find((item) => {
      //       return item?.runnerName == res?.runnerName;
      //     });
      //     if (obj?.runnerName == res?.runnerName && obj?.rt == res?.rt) {
      //       return obj;
      //     } else {
      //       return {
      //         ...res,
      //         ib: false,
      //         rt: checkLayOdds(Number(res?.rt)),
      //       };
      //     }
      //   });
      // } else {
      //   lay_odds = newLayOdds;
      // }
      let newArray = [...back_odds].concat([...lay_odds]);

      setBetLoader((loaderPrev) => {
        if (!loaderPrev) {
          setBetSlipObject((prev) => {
            if (prev?.playType == "bookmaker") {
              if (newArray?.length > 0) {
                let updated = newArray?.find((res) => {
                  return (
                    res?.ib == prev?.object?.ib &&
                    res?.RunnerName == prev?.object?.RunnerName
                  );
                });
                if (!isEmpty(updated)) {
                  return {
                    ...prev,
                    odds: updated?.rt,
                    ts: message?.results?.ts,
                    isBetDisabled: false,
                  };
                } else {
                  return { ...prev, isBetDisabled: true };
                }
              } else {
                return { ...prev, isBetDisabled: true };
              }
            } else {
              return prev;
            }
          });
        }
        return loaderPrev;
      });
      setBookmakerLayOdds(lay_odds);
      setBookmakerBackOdds(back_odds);
      if (message?.results?.rt?.length == 0) {
        setBookmakerSuspended(true);
      } else {
        setBookmakerSuspended(false);
      }
    } else {
      setBookmakerSuspended(true);
    }
  };

  const listenDiamondFancy = (message) => {
    if (message?.eventId === parmas?.eventId) {
      if (message?.results?.length > 0) {
        let filtered = categoryArray?.filter((f) =>
          message?.results?.some((item) => item?.categoryType === f.type)
        );
        setFancyHeader(
          filtered?.length > 0
            ? [{ type: "All", name: "All" }, ...filtered]
            : [{ type: "All" }]
        );
        if (!isEmpty(user)) {
          setBetLoader((loaderPrev) => {
            if (!loaderPrev) {
              setBetSlipObject((prev) => {
                if (prev?.playType == "fancy") {
                  if (message?.results?.length > 0) {
                    let updated = message?.results?.find((res) => {
                      return res?.selectionId == prev?.object?.ri;
                    });
                    if (!isEmpty(updated)) {
                      if (updated?.rt[0]?.rt > 0) {
                        let innerObject = updated?.rt?.find((item) => {
                          return (
                            item?.ri == prev?.object?.ri &&
                            item?.ib == prev?.object?.ib
                          );
                        });
                        return {
                          ...prev,
                          object: {
                            ...prev?.object,
                            ...innerObject,
                            odds: innerObject,
                            ts: updated?.ts,
                            ms: updated?.ms,
                          },
                          odds: innerObject?.rt,
                          isBetDisabled: false,
                        };
                      } else {
                        return { ...prev, isBetDisabled: true };
                      }
                    } else {
                      return { ...prev, isBetDisabled: true };
                    }
                  }
                } else {
                  return prev;
                }
              });
            }
            return loaderPrev;
          });
          setFancyPosition((prev) => {
            setFancy(
              message.results.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              })
            );
            setFancyUpdate(
              message.results.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              })
            );
            return prev;
          });
        } else {
          setFancy((prev) => {
            return message?.results?.map((res) => {
              let obj = prev?.find(
                (item) => item.selectionId == res.selectionId
              );
              return {
                ...res,
                odds: res,
                fancyName: res.name,
                position: !isEmpty(obj) ? obj?.position : "",
                newPosition:
                  Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
              };
            });
          });
          setFancyUpdate((prev) => {
            return message?.results?.map((res) => {
              let obj = prev?.find(
                (item) => item.selectionId == res.selectionId
              );
              return {
                ...res,
                odds: res,
                fancyName: res.name,
                position: !isEmpty(obj) ? obj?.position : "",
                newPosition:
                  Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
              };
            });
          });
        }
      }
    }
  };

  const listenTie = (message) => {
    const socket_data =
      (message &&
        message?.results?.find((item) => item.eventId == parmas?.eventId)) ||
      [];
    if (
      socket_data &&
      socket_data?.eventId &&
      parmas?.eventId == socket_data?.eventId
    ) {
      if (socket_data.rt?.length) {
        const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
        const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
        setBackOddsTie(back_odds);
        setLayOddsTie(lay_odds);
      }
    }
  };

  const handelBetFairPositions = (slipObj) => {
    let selectionsTemp = [...runner]?.map((res) => {
      return {
        ...res,
        position: res?.position || 0,
        newPosition: res?.newPosition || 0,
      };
    });
    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let backProfit = Number(
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice)
        );
        let backLoss = Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        let backProfit = Number(betSlipObject?.bidPrice);
        let backLoss =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };

  const handelBetFairPositionsMulti = (slipObj, market) => {
    let selectionsTemp = [...marketNew]
      ?.filter((res) => {
        return res?.market == market;
      })
      ?.map((item) => {
        return {
          ...item,
          position: item?.position || 0,
          newPosition: item?.newPosition || 0,
        };
      });
    let newArray = selectionsTemp[0]?.jsonData;
    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let backProfit = Number(
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice)
        );
        let backLoss = Number(betSlipObject?.bidPrice);
        if (Math.abs(newArray[0]?.position) > 0) {
          newArray = newArray?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(rl.position) + backProfit,
              };
            } else {
              return {
                ...rl,
                newPosition: Number(rl.position) - backLoss,
              };
            }
          });
        } else {
          newArray = newArray?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(backProfit),
              };
            } else {
              return {
                ...rl,
                newPosition: -Number(backLoss),
              };
            }
          });
        }
      } else {
        let backProfit = Number(betSlipObject?.bidPrice);
        let backLoss =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        if (Math.abs(newArray[0]?.position) > 0) {
          newArray = newArray?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(rl.position) - backLoss,
              };
            } else {
              return {
                ...rl,
                newPosition: Number(rl.position) + backProfit,
              };
            }
          });
        } else {
          newArray = newArray?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: -Number(backLoss),
              };
            } else {
              return {
                ...rl,
                newPosition: Number(backProfit),
              };
            }
          });
        }
      }
    }
    setMarketNew((prev) => {
      return prev?.map((res) => {
        if (res?.market == market) {
          return { ...res, jsonData: newArray };
        } else {
          return res;
        }
      });
    });
  };
  const handelFancyPositions = (slipObj) => {
    if (slipObj?.bidPrice >= 0) {
      let updatedFancy = fancy?.map((res) => {
        return {
          ...res,
          newPosition: res?.newPosition || 0,
          position: res?.position || 0,
        };
      });
      if (slipObj?.object?.ib) {
        let currentPosition = updatedFancy.find(
          (rl) => rl.selectionId == slipObj?.object?.selectionId
        );
        if (
          Math.abs(currentPosition.position) > 0 &&
          !isEmpty(currentPosition.type)
        ) {
          let backLoss = slipObj?.bidPrice + currentPosition.position;
          currentPosition.newPosition = backLoss;
          const result = updatedFancy.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });

          setFancyUpdate(result);
          setFancy(result);
        } else {
          let backLoss = slipObj?.bidPrice;
          currentPosition.newPosition = backLoss;
          const result = updatedFancy.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyUpdate(result);
          setFancy(result);
        }
      } else {
        let currentPosition = updatedFancy.find(
          (rl) => rl?.selectionId == slipObj?.object?.selectionId
        );
        if (Math.abs(currentPosition?.position) > 0) {
          let backProfit = (slipObj?.bidPrice * slipObj.odds) / 100;
          currentPosition.newPosition = backProfit + currentPosition.position;
          const result = updatedFancy.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });

          setFancyUpdate(result);
          setFancy(result);
        } else {
          let backProfit = (slipObj?.bidPrice * slipObj?.odds) / 100;
          currentPosition.newPosition = backProfit;
          const result = updatedFancy.map((data) => {
            if (data.selectionId == currentPosition?.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyUpdate(result);
          setFancy(result);
        }
      }
    }
  };
  const handelBookmakerPositions = (slipObj) => {
    let selectionsTemp = [...bookmaker]?.map((res) => {
      return {
        ...res,
        position: res?.position || 0,
        newPosition: res?.newPosition || 0,
      };
    });
    if (detail?.gameType == "soccer") {
      if (slipObj?.bidPrice > 0) {
        if (slipObj?.object.ib) {
          let backProfit = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
          let backLoss = Number(slipObj?.bidPrice);
          if (Math.abs(selectionsTemp[0]?.position) > 0) {
            setBookmaker(
              selectionsTemp?.map((rl) => {
                if (rl.selectionID == slipObj?.object?.selectionID) {
                  return {
                    ...rl,
                    newPosition: Number(rl.position) + Number(backProfit),
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: Number(rl.position) - Number(backLoss),
                  };
                }
              })
            );
          } else {
            let backProfit = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
            let backLoss = Number(slipObj?.bidPrice);

            setBookmaker(
              selectionsTemp?.map((rl) => {
                if (rl.selectionID == slipObj?.object?.selectionID) {
                  return {
                    ...rl,
                    newPosition: Number(backProfit),
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: -Number(backLoss),
                  };
                }
              })
            );
          }
        } else {
          if (Math.abs(selectionsTemp[0]?.position) > 0) {
            let backProfit = Number(slipObj?.bidPrice);
            let backLoss = Number((slipObj?.odds / 100) * slipObj?.bidPrice);

            setBookmaker(
              selectionsTemp?.map((rl) => {
                if (rl.selectionID == slipObj?.object?.selectionID) {
                  return {
                    ...rl,
                    newPosition: Number(rl.position) + Number(backLoss),
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: Number(rl.position) + Number(backProfit),
                  };
                }
              })
            );
          } else {
            let backProfit = Number(slipObj?.bidPrice);
            let backLoss = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
            setBookmaker(
              selectionsTemp?.map((rl) => {
                if (rl.selectionID == slipObj?.object?.selectionID) {
                  return {
                    ...rl,
                    newPosition: -Number(backLoss),
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: Number(backProfit),
                  };
                }
              })
            );
          }
        }
      }
    } else {
      if (slipObj?.bidPrice > 0) {
        if (slipObj?.object.ib) {
          let backProfit = Number(
            (Number(slipObj?.odds) / 100) * Number(slipObj?.bidPrice)
          );
          let backLoss = Number(slipObj?.bidPrice);
          if (
            Math.abs(selectionsTemp[0]?.position) > 0 ||
            Math.abs(selectionsTemp[1]?.position) > 0
          ) {
            setBookmaker(
              selectionsTemp?.map((rl) => {
                if (rl.SelectionId == slipObj?.object?.SelectionId) {
                  return {
                    ...rl,
                    newPosition: add(Number(rl.position), Number(backProfit)),
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: subtract(
                      Number(rl.position),
                      Number(backLoss)
                    ),
                  };
                }
              })
            );
          } else {
            setBookmaker(
              selectionsTemp?.map((rl) => {
                if (rl.SelectionId == slipObj?.object?.SelectionId) {
                  return {
                    ...rl,
                    newPosition: Number(backProfit),
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: -Number(backLoss),
                  };
                }
              })
            );
          }
        } else {
          let backProfit = Number(slipObj?.bidPrice);
          let backLoss = Number(
            (Number(slipObj?.odds) / 100) * Number(slipObj?.bidPrice)
          );
          if (
            Math.abs(selectionsTemp[0]?.position) > 0 ||
            Math.abs(selectionsTemp[1]?.position) > 0
          ) {
            setBookmaker(
              selectionsTemp?.map((rl) => {
                if (rl.SelectionId == slipObj?.object?.SelectionId) {
                  // console.log(Number(rl.position) , Number(backLoss),Number(rl.position) - Number(backLoss),'=========')
                  return {
                    ...rl,
                    newPosition: Number(rl.position) - Number(backLoss),
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: Number(rl.position) + Number(backProfit),
                  };
                }
              })
            );
          } else {
            setBookmaker(
              selectionsTemp?.map((rl) => {
                if (rl.SelectionId == slipObj?.object?.SelectionId) {
                  return {
                    ...rl,
                    newPosition: -Number(backLoss),
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: Number(backProfit),
                  };
                }
              })
            );
          }
        }
      }
    }
  };
  const handelTiePosition = (slipObj) => {
    let selectionsTemp = [...tieData]?.map((res) => {
      return {
        ...res,
        position: res?.position || 0,
        newPosition: res?.newPosition || 0,
      };
    });
    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let currentPosition = selectionsTemp.find(
          (rl) => rl.SelectionId == slipObj?.object?.ri
        );
        let otherTeamPosition;
        let thirdTeamPosition;
        if (slipObj.RunnerName == "The Draw") {
          otherTeamPosition = selectionsTemp[0];
          thirdTeamPosition = selectionsTemp[1];
        } else {
          otherTeamPosition = selectionsTemp.find(
            (rl) =>
              rl.SelectionId !== slipObj?.object?.ri &&
              rl.RunnerName != "The Draw"
          );
          thirdTeamPosition = selectionsTemp[2] ? selectionsTemp[2] : false;
        }
        let backProfit = (betSlipObject.odds - 1) * betSlipObject?.bidPrice; //(slipObj.rt - 1) * betSelectionObj?.bidPrice;
        let backLoss = betSlipObject?.bidPrice;
        if (
          Math.abs(selectionsTemp[0]?.position) > 0 ||
          Math.abs(selectionsTemp[1]?.position) > 0
        ) {
          currentPosition.newPosition = currentPosition.position + backProfit;
          otherTeamPosition.newPosition = otherTeamPosition.position - backLoss;
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition =
              thirdTeamPosition.position - backLoss;
          }
          const result = selectionsTemp.map((data) => {
            if (data.SelectionId == currentPosition.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (data.SelectionId == otherTeamPosition.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (
              thirdTeamPosition &&
              data.SelectionId == thirdTeamPosition.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            return data;
          });
          setTieData(result);
        } else {
          currentPosition.newPosition = backProfit;
          otherTeamPosition.newPosition = -backLoss;
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition = -backLoss;
          }
          const result = selectionsTemp.map((data) => {
            if (data.SelectionId == currentPosition.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (data.SelectionId == otherTeamPosition.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (
              thirdTeamPosition &&
              data.SelectionId == thirdTeamPosition.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            return data;
          });
          setTieData(result);
        }
      } else {
        let currentPosition = selectionsTemp.find(
          (rl) => rl.SelectionId == slipObj?.object?.ri
        );
        let otherTeamPosition;
        let thirdTeamPosition;
        if (slipObj.RunnerName == "The Draw") {
          otherTeamPosition = selectionsTemp[0];
          thirdTeamPosition = selectionsTemp[1];
        } else {
          otherTeamPosition = selectionsTemp.find(
            (rl) =>
              rl.SelectionId !== slipObj?.object?.ri &&
              rl.RunnerName != "The Draw"
          );
          thirdTeamPosition = selectionsTemp[2] ? selectionsTemp[2] : false;
        }

        if (
          Math.abs(selectionsTemp[0]?.position) > 0 ||
          Math.abs(selectionsTemp[1]?.position) > 0
        ) {
          let backProfit = betSlipObject?.bidPrice;
          let backLoss = (betSlipObject.odds - 1) * betSlipObject?.bidPrice; //(slipObj.rt - 1) * betSelectionObj?.bidPrice;
          currentPosition.newPosition =
            Number(currentPosition?.position) - Number(backLoss);
          otherTeamPosition.newPosition =
            Number(otherTeamPosition?.position) + Number(backProfit);
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition =
              Number(thirdTeamPosition?.position) + Number(backProfit);
          }
          const result = selectionsTemp.map((data) => {
            if (data?.SelectionId == currentPosition?.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition)
                : 0;
            }
            if (data?.SelectionId == otherTeamPosition?.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition)
                : 0;
            }
            if (
              thirdTeamPosition &&
              data?.SelectionId == thirdTeamPosition?.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition)
                : 0;
            }
            return data;
          });
          setTieData(result);
        } else {
          let backProfit = betSlipObject?.bidPrice;
          let backLoss = (betSlipObject.odds - 1) * betSlipObject?.bidPrice;
          currentPosition.newPosition = -Number(backLoss);
          otherTeamPosition.newPosition = Number(backProfit);
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition = Number(backProfit);
          }
          const result = selectionsTemp.map((data) => {
            if (data?.SelectionId == currentPosition?.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition)
                : 0;
            }
            if (data?.SelectionId == otherTeamPosition?.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition)
                : 0;
            }
            if (
              thirdTeamPosition &&
              data?.SelectionId == thirdTeamPosition?.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition)
                : 0;
            }
            return data;
          });
          setTieData(result);
        }
      }
    }
  };
  const handelTossPosition = (slipObj) => {
    if (betSlipObject?.bidPrice > 0) {
      let selectionsTemp = [...tossData].map((res) => {
        return {
          ...res,
          newPosition: res?.newPosition || 0,
          position: res?.position || 0,
        };
      });
      let current = selectionsTemp.find((rl) => {
        return rl?.SelectionId === slipObj?.object?.SelectionId;
      });
      let other = selectionsTemp.find((rl) => {
        return rl?.SelectionId !== slipObj?.object?.SelectionId;
      });
      if (Math.abs(current?.position) > 0 || Math.abs(other?.position) > 0) {
        let backProfit = betSlipObject.odds * betSlipObject?.bidPrice;
        let backLoss = betSlipObject?.bidPrice;
        current.newPosition = Number(current?.position) + Number(backProfit);
        other.newPosition = Number(other?.position) - Number(backLoss);
        const result = selectionsTemp.map((data) => {
          if (data?.SelectionId == current?.SelectionId) {
            data.newPosition = current?.newPosition
              ? parseFloat(current?.newPosition)
              : 0;
          }
          if (data?.SelectionId == other?.SelectionId) {
            data.newPosition = other?.newPosition
              ? parseFloat(other?.newPosition)
              : 0;
          }
          return data;
        });
        setTossData(result);
      } else {
        let backProfit = betSlipObject.odds * betSlipObject?.bidPrice;
        let backLoss = -Number(betSlipObject?.bidPrice);
        const result = selectionsTemp.map((data) => {
          if (data?.SelectionId == current?.SelectionId) {
            data.newPosition = backProfit ? parseFloat(backProfit) : 0;
          }
          if (data?.SelectionId == other?.SelectionId) {
            data.newPosition = backLoss ? parseFloat(backLoss) : 0;
          }
          return data;
        });
        setTossData(result);
      }
    }
  };

  const handelLineMarketPosition = (slipObj) => {
    if (betSlipObject?.bidPrice > 0) {
      let selectionsTemp = [...lineMarket].map((res) => {
        return {
          ...res,
          newPosition: res?.newPosition || 0,
          position: res?.position || 0,
        };
      });
      let current = selectionsTemp.find((rl) => {
        return rl?.selectionId === slipObj?.object?.selectionId;
      });
      if (Math.abs(current?.position) > 0) {
        let backProfit = betSlipObject.odds * betSlipObject?.bidPrice;
        let backLoss = betSlipObject?.bidPrice;
        setLineMarket((prev) => {
          return prev?.map((res) => {
            if (res?.selectionId === slipObj?.object?.selectionId) {
              return {
                ...res,
                ruunerArray: res?.ruunerArray?.map((item) => {
                  if (item?.key == betSlipObject?.teamName) {
                    return {
                      ...item,
                      newPosition: Number(res?.position) + Number(backProfit),
                    };
                  } else {
                    return {
                      ...item,
                      newPosition: Number(res?.position) - Number(backLoss),
                    };
                  }
                }),
              };
            } else {
              return res;
            }
          });
        });
      } else {
        let backProfit = Number(betSlipObject.odds * betSlipObject?.bidPrice);
        let backLoss = -Number(betSlipObject?.bidPrice);
        setLineMarket((prev) => {
          return prev?.map((res) => {
            if (res?.selectionId === slipObj?.object?.selectionId) {
              return {
                ...res,
                ruunerArray: res?.ruunerArray?.map((item) => {
                  if (item?.key == betSlipObject?.teamName) {
                    return {
                      ...item,
                      newPosition: backProfit,
                    };
                  } else {
                    return {
                      ...item,
                      newPosition: backLoss,
                    };
                  }
                }),
              };
            } else {
              return res;
            }
          });
        });
      }
    }
  };

  const resetPosition = (type) => {
    if (type == "betFair") {
      setRunner((current) =>
        current.map((res) => {
          return {
            ...res,
            position: Math.abs(res?.position) > 0 ? res?.position : 0,
            newPosition: 0,
          };
        })
      );
    } else if (type == "bookmaker") {
      setBookmaker((current) =>
        current?.map((res) => {
          return {
            ...res,
            position: Math.abs(res?.position) > 0 ? res?.position : 0,
            newPosition: 0,
          };
        })
      );
    } else if (type == "tie") {
      setTieData((current) =>
        current.map((res) => {
          return {
            ...res,
            position: Math.abs(res?.position) > 0 ? res?.position : 0,
            newPosition: 0,
          };
        })
      );
    } else if (type == "toss") {
      setTossData((current) =>
        current.map((res) => {
          return {
            ...res,
            position: Math.abs(res?.position) > 0 ? res?.position : 0,
            newPosition: 0,
          };
        })
      );
    } else if (type == "multi") {
      setMarketNew((current) =>
        current?.map((res) => {
          return {
            ...res,
            jsonData: res?.jsonData?.map((newObj) => {
              return {
                ...newObj,
                position: Math.abs(newObj?.position) > 0 ? newObj?.position : 0,
                newPosition: 0,
              };
            }),
          };
        })
      );
    } else if (type == "line") {
      setLineMarket((current) =>
        current.map((res) => {
          return {
            ...res,
            ruunerArray: res?.ruunerArray?.map((item) => {
              return {
                ...item,
                position: Math.abs(item?.position) > 0 ? item?.position : 0,
                newPosition: 0,
              };
            }),
          };
        })
      );
    }
    setResetBetPosition(false);
    resetBetSlipObject();
  };

  const updatePositionAfterBetPlace = (type) => {
    if (type == "betFair") {
      secureLocalStorage?.removeItem("betFair");
      secureLocalStorage.setItem(
        "betFair",
        runner?.map((res) => {
          return {
            selectionId: res?.SelectionId,
            position: res?.newPosition,
          };
        })
      );
      setPosition((prev) => {
        return {
          ...prev,
          betFair: runner?.map((res) => {
            return {
              selectionId: res?.SelectionId,
              position: res?.newPosition,
            };
          }),
        };
      });
      setRunner(
        runner?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "fancy") {
      secureLocalStorage?.removeItem("fancy");
      secureLocalStorage.setItem(
        "fancy",
        fancy
          ?.filter((res) => {
            return (
              Math.abs(res?.position) > 0 || Math.abs(res?.newPosition) > 0
            );
          })
          ?.map((res) => {
            return {
              selectionId: res?.selectionId,
              position: res?.newPosition || res?.position,
            };
          })
      );
      setFancy((prev) => {
        return prev?.map((res) => {
          return {
            ...res,
            position: res?.newPosition || res?.position,
            newPosition: 0,
          };
        });
      });
      setFancyUpdate((prev) => {
        return prev?.map((res) => {
          return {
            ...res,
            position: res?.newPosition || res?.position,
            newPosition: 0,
          };
        });
      });
    } else if (type == "bookmaker") {
      secureLocalStorage?.removeItem("bookmaker");
      secureLocalStorage.setItem(
        "bookmaker",
        bookmaker?.map((res) => {
          return {
            selectionId: `B${res?.SelectionId}`,
            position: res?.newPosition,
          };
        })
      );
      setPosition((prev) => {
        return {
          ...prev,
          bookMaker: bookmaker?.map((res) => {
            return {
              ...res,
              position: res?.newPosition,
              newPosition: 0,
            };
          }),
        };
      });
      setBookmaker(
        bookmaker?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "tie") {
      secureLocalStorage?.removeItem("tie");
      secureLocalStorage.setItem(
        "tie",
        tieData?.map((res) => {
          return {
            selectionId: res?.SelectionId,
            position: res?.newPosition,
          };
        })
      );
      setPosition((prev) => {
        return {
          ...prev,
          tieData: tieData?.map((res) => {
            return {
              ...res,
              position: res?.newPosition,
              newPosition: 0,
            };
          }),
        };
      });
      setTieData(
        tieData?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "toss") {
      secureLocalStorage?.removeItem("toss");
      secureLocalStorage.setItem(
        "toss",
        tossData?.map((res) => {
          return {
            selectionId: res?.SelectionId,
            position: res?.newPosition,
          };
        })
      );
      setPosition((prev) => {
        return {
          ...prev,
          tossData: tossData?.map((res) => {
            return {
              ...res,
              position: res?.newPosition,
              newPosition: 0,
            };
          }),
        };
      });
      setTossData(
        tossData?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "multi") {
      let newly = marketNew?.map((res) => {
        if (res?.market == betSlipObject?.selectMarket) {
          return {
            ...res,
            jsonData: res?.jsonData?.map((newObj) => {
              return {
                ...newObj,
                position: newObj?.newPosition,
                newPosition: 0,
              };
            }),
          };
        } else {
          return res;
        }
      });
      setMarketNew(newly);
      secureLocalStorage?.removeItem("betFairMulti");
      secureLocalStorage.setItem(
        "betFairMulti",
        newly
          ?.map((res) => {
            return res?.jsonData;
          })
          ?.flat()
          ?.map((res) => {
            return {
              selectionId: res?.SelectionId,
              position: isNumber(res?.position) ? res?.position : 0,
            };
          })
      );
    } else if (type == "line") {
      secureLocalStorage?.removeItem("line");
      secureLocalStorage.setItem(
        "line",
        lineMarket?.filter((res) => {
          if (
            !isEmpty(res?.ruunerArray?.find((item) => item?.newPosition > 0))
          ) {
            return {
              ...res,
              ruunerArray: res?.ruunerArray?.map((item) => {
                return { ...item, position: item?.newPosition, position: 0 };
              }),
            };
          }
          if (
            !isEmpty(
              res?.ruunerArray?.find(
                (item) => isEmpty(item?.newPosition) && isNumber(item?.position)
              )
            )
          ) {
            return res;
          }
        })
      );
      setLineMarket((prev) => {
        return prev?.map((res) => {
          if (!isEmpty(res?.ruunerArray?.find((item) => item?.newPosition))) {
            return {
              ...res,
              ruunerArray: res?.ruunerArray?.map((item) => {
                return { ...item, position: item?.newPosition, newPosition: 0 };
              }),
            };
          } else {
            return res;
          }
        });
      });
    }
    setBetPlace({});
    resetBetSlipObject({});
  };

  useEffect(() => {
    if (showBetDialog) {
      setTvClass(false);
      if (!isEmpty(betSlipObject)) {
        if (
          betSlipObject?.playType == "betFair" &&
          betSlipObject?.newType == "normal"
        ) {
          handelBetFairPositions(betSlipObject);
        } else if (betSlipObject?.playType == "fancy") {
          handelFancyPositions(betSlipObject);
        } else if (betSlipObject?.playType == "bookmaker") {
          handelBookmakerPositions(betSlipObject);
        } else if (betSlipObject?.playType == "tie") {
          handelTiePosition(betSlipObject);
        } else if (betSlipObject?.playType == "toss") {
          handelTossPosition(betSlipObject);
        } else if (
          betSlipObject?.playType == "betFair" &&
          betSlipObject?.newType == "multi"
        ) {
          handelBetFairPositionsMulti(
            betSlipObject,
            betSlipObject?.selectMarket
          );
        } else if (betSlipObject?.playType == "line") {
          handelLineMarketPosition(betSlipObject);
        }
      }
    }
  }, [betSlipObject, showBetDialog]);

  useEffect(() => {
    if (resetBetPosition) {
      if (betSlipObject?.newType == "multi") {
        resetPosition("multi");
      } else {
        resetPosition(betSlipObject?.playType);
      }
    }
  }, [resetBetPosition, position]);

  const getFancyData = async () => {
    const { status, data: message } = await apiGet(
      apiPath.getFancyOdds + `?eventId=${parmas.eventId}`
    );
    if (status === 200) {
      if (message.success) {
        if (message?.results?.length > 0) {
          let filtered = categoryArray?.filter((f) =>
            message?.results?.some((item) => item?.categoryType === f.type)
          );
          setFancyHeader(
            filtered?.length > 0
              ? [
                  { type: "All", name: "All" },
                  ...filtered,
                  // { type: "More", name: "More" },
                ]
              : [{ type: "All" }]
          );
          if (message.results?.length > 0) {
            setFancySportTab("fancy");
          }
          if (!isEmpty(user)) {
            setFancyPosition((prev) => {
              setFancy(
                message.results.map((res) => {
                  let obj = prev?.find(
                    (item) => item.selectionId == res.selectionId
                  );
                  return {
                    ...res,
                    odds: res,
                    fancyName: res.name,
                    position: !isEmpty(obj) ? obj?.position : "",
                    newPosition:
                      Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                  };
                })
              );
              setFancyUpdate(
                message.results.map((res) => {
                  let obj = prev?.find(
                    (item) => item.selectionId == res.selectionId
                  );
                  return {
                    ...res,
                    odds: res,
                    fancyName: res.name,
                    position: !isEmpty(obj) ? obj?.position : "",
                    newPosition:
                      Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                  };
                })
              );
              return prev;
            });
          } else {
            setFancy((prev) => {
              return message?.results?.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              });
            });
            setFancyUpdate((prev) => {
              return message?.results?.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              });
            });
          }
        }
      }
    }
  };

  const getLineMarketData = async () => {
    const { status, data: message } = await apiGet(
      apiPath.lineMarket + `?eventId=${parmas.eventId}`
    );
    if (status === 200) {
      if (message.success) {
        if (message?.results?.length > 0) {
          setLineMarket(
            message?.results?.map((res) => {
              return {
                ...res,
                ruunerArray: [
                  {
                    name: "Odd",
                    odds: res?.b1,
                    key: "odd",
                  },
                  {
                    name: "Even",
                    odds: res?.b1,
                    key: "even",
                  },
                ],
              };
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    getMatchDetails();
    getMatchOdds();
    getFancyData();
    getLineMarketData();
    getBookmakerOdds();
  }, [parmas]);

  useEffect(() => {
    if (!isEmpty(user)) {
      if (!isEmpty(detail)) {
        startTransition(() => {
          if (
            secureLocalStorage.getItem("betFair") == null ||
            secureLocalStorage.getItem("bookmaker") == null
          ) {
            getBetPosition();
          } else {
            let obj = {
              betFair:
                secureLocalStorage.getItem("betFair")?.length > 0
                  ? secureLocalStorage.getItem("betFair")
                  : [],
              bookmaker:
                secureLocalStorage.getItem("bookmaker")?.length > 0
                  ? secureLocalStorage.getItem("bookmaker")
                  : [],
              tie:
                secureLocalStorage.getItem("tie")?.length > 0
                  ? secureLocalStorage.getItem("tie")
                  : [],
              toss:
                secureLocalStorage.getItem("toss")?.length > 0
                  ? secureLocalStorage.getItem("toss")
                  : [],
            };
            updatePosition(obj);
          }
          if (secureLocalStorage.getItem("line")?.length > 0) {
            updatePositionLine(secureLocalStorage.getItem("line"));
          }
          if (secureLocalStorage.getItem("fancy")) {
            let obj = secureLocalStorage.getItem("fancy") || [];
            updatedFancyPosition(obj);
          } else {
            getFancyBetPosition();
          }
        });
      }
    }
  }, [detail]);

  useEffect(() => {
    // if (betPlace?.betType == "fancy") {
    //   getFancyBetPosition();
    // }else if (betPlace?.betType == "multi") {
    //   getBetPositionMulti(marketId)
    // } else {
    //   getBetPosition();
    // }
    if (betPlace?.isPlaced && betPlace?.type == "matched") {
      if (betPlace?.betType == "fancy") {
        getFancyBetPosition();
      } else if (betPlace?.betType == "multi") {
        updatePositionAfterBetPlace("multi");
      } else {
        updatePositionAfterBetPlace(betPlace?.betType);
      }
    } else if (betPlace?.isPlaced && betPlace?.type == "error") {
      if (betPlace?.betType == "fancy") {
        getFancyBetPosition();
      } else if (
        betPlace?.betType == "betFair" ||
        betPlace?.betType == "bookmaker"
      ) {
        resetPosition(betPlace?.betType);
      } else {
        resetPosition(betPlace?.betType);
      }
    }
  }, [betPlace, marketId, position]);

  const getScore = (message) => {
    let updated = JSON.parse(message);
    if (updated?.length > 0) {
      setScore(updated?.find((res) => res?.eventId == detail?.eventId)?.score);
    }
  };

  const getSocket = () => {
    if (detail?.eventId && checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${
            detail?.socketUrl ? detail?.socketUrl : process.env.REACT_APP_SOCKET
          }?token=${randomId}&userType=front&eventId=${detail?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${
            detail?.socketUrl ? detail?.socketUrl : process.env.REACT_APP_SOCKET
          }?user_id=${randomId}&eventId=${detail?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      setSocketId(newSocket);
      if (!isEmpty(user)) {
        newSocket.on("listenDiamondFancy", listenDiamondFancy);
        newSocket.on("listenBetFairOdds", listenBetfairOdds);
        newSocket.on("listenBookmakerOdds", listenBookmakerData);
        newSocket.on("listenBetFairTieOdds", listenTie);
        newSocket.on("scoreAll", getScore);
        newSocket.emit("setPremiumFancy", {
          user_id: user?.user?._id ? user?.user?._id : randomId,
          eventId: parmas.eventId,
        });
      } else {
        newSocket.on("listenDiamondFancy", listenDiamondFancy);
        newSocket.on("listenBetFairOdds", listenBetfairOdds);
        newSocket.on("scoreAll", getScore);
        newSocket.on("listenBookmakerOdds", listenBookmakerData);
      }

      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => socketObj.disconnect();
    }
  };

  useEffect(() => {
    if (checkRefresh && detail?.eventId) {
      getSocket();
    }
  }, [detail?.eventId, checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      setTvCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setTvCheckRefresh(false);
      setCheckRefresh(false);
    }
  });

  useEffect(() => {
    let header = document.getElementById("header")
      ? Number(document.getElementById("header")?.offsetHeight) + 2
      : 0;
    setScrollHeight(header);
  }, []);

  useEffect(() => {
    var body = document.body,
      html = document.documentElement;
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    if (height >= 1800) {
      if (showBetDialog) {
        startTransition(() => {
          setTvClass(false);
        });
      } else {
        if (y >= 298) {
          startTransition(() => {
            setTvClass(true);
          });
        } else {
          startTransition(() => {
            setTvClass(false);
          });
        }
      }
    } else if (inRange(height, 1200, 10000)) {
      if (showBetDialog) {
        startTransition(() => {
          setTvClass(false);
        });
      } else {
        if (y >= 298) {
          startTransition(() => {
            setTvClass(true);
          });
        } else {
          startTransition(() => {
            setTvClass(false);
          });
        }
      }
    } else if (inRange(height, 0, 1200) && tvClass) {
      if (showBetDialog) {
        startTransition(() => {
          setTvClass(false);
        });
      } else {
        if (y >= 298) {
          startTransition(() => {
            setTvClass(true);
          });
        } else {
          startTransition(() => {
            setTvClass(false);
          });
        }
      }
    }
  }, [y, showBetDialog]);

  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      setControls(false);
    }, 4000);
    return () => window.clearTimeout(timeoutID);
  }, [controls]);

  const display = () => {
    if (marketNew?.length > 0) {
      return detail?.eventName?.includes(" SRL ") ||
        detail?.eventName?.includes(" T10 ")
        ? ""
        : runner?.length > 0 &&
            detail?.matchOdds == "on" &&
            marketNew?.map((res) => {
              return (
                <RunnerNew
                  parmas={parmas}
                  user={user}
                  res={res}
                  newType="multi"
                  betFairMs={!isEmpty(res?.odds) ? res?.odds?.ms : 3}
                  title={res?.market}
                  setRunner={setRunner}
                  detail={detail}
                  getMatchOdds={getMatchOdds}
                  getBetPosition={getBetPosition}
                  tieData={tieData}
                  totalMatched={totalMatched}
                  runner={res?.jsonData}
                  market={marketNew}
                  backOdds={
                    !isEmpty(res?.odds)
                      ? res?.odds?.rt?.filter((rt) => rt.ib) || []
                      : []
                  }
                  layOdds={
                    !isEmpty(res?.odds)
                      ? res?.odds?.rt?.filter((rt) => !rt.ib) || []
                      : []
                  }
                  back_oddsTie={[]}
                  lay_oddsTie={[]}
                  setLimit={setLimit}
                  scrollHeight={scrollHeight}
                  setBetSlipObject={setBetSlipObject}
                  setShowBetDialog={setShowBetDialog}
                  setShowMarketDepthPopup={setShowMarketDepthPopup}
                  handelBetFairPositions={handelBetFairPositions}
                  profileData={
                    detail?.matchSetting?.length > 0
                      ? { ...detail, arrayCheck: "details" }
                      : { ...profileData, arrayCheck: "profile" }
                  }
                />
              );
            });
    }
  };

  return (
    <div>
      <LayoutComponent visibilityType={true} className="position-relative">
        <div className="full-market full-marketer">
          {!isEmpty(user) && detail?.status == "in_play" && (
            <div class="topnav">
              <p
                onClick={() => setTopTab("live")}
                class={topTab === "live" ? "active" : ""}
              >
                Live
              </p>
              <p
                onClick={() => setTopTab("scoreboard")}
                class={topTab === "scoreboard" ? "active" : ""}
              >
                ScoreBoard
              </p>
            </div>
          )}
          <div>
            <div className="live-header">
              <span>{detail?.eventName?.split(" v ")[0]}</span>
              {score ? <span className="score_color">{score}</span> : "-"}
              <span>{detail?.eventName?.split(" v ")[1]}</span>
            </div>
          </div>
          {isEmpty(user) &&
            !isEmpty(detail.scoreId) &&
            detail?.status == "in_play" && <BattingIframe detail={detail} />}

          {!isEmpty(user) &&
          detail?.status == "in_play" &&
          !isEmpty(detail?.channel) ? (
            topTab === "live" ? (
              <iframe
                title="Live TV Player"
                onClick={() => setControls(true)}
                src={`https://5por-tt1.top/getscore.php?chid=${detail?.channel}`}
                id="ContentPlaceHolder1_myFrame1"
                style={{ width: "100%", height: "230px" }}
                class="sc-dUjcNx eLUYUT"
              />
            ) : (
              <BattingIframe detail={detail} />
            )
          ) : (
            ""
          )}
          <div className="balance-label-detail position-relative text-start d-flex justify-content-between exchange-label">
            <Container className="p-0 d-flex justify-content-between">
              <span>Exchange</span>
              <div className="text-yellow balance-label-inner">
                <span>Matched</span>
                <strong className="px-2">
                  {totalMatched > 0 && `${priceFormat(totalMatched)}`}
                </strong>
                {totalMatched < 10000 && (
                  <strong className="bg-danger rounded text-white low-button">
                    Low
                  </strong>
                )}
              </div>
            </Container>
          </div>
          {detail?.matchOdds == "on" &&
            runner?.length > 0 &&
            runnerBackOdds?.length > 0 && (
              <Runner
                user={user}
                betFairMs={betFairMs}
                detail={detail}
                tieData={tieData}
                runner={runner}
                backOdds={runnerBackOdds}
                layOdds={runnerLayOdds}
                marketName={marketName}
                setMarketName={setMarketName}
                back_oddsTie={back_oddsTie}
                lay_oddsTie={lay_oddsTie}
                totalMatched={totalMatched}
                setLimit={setLimit}
                scrollHeight={scrollHeight}
                setBetSlipObject={setBetSlipObject}
                setShowBetDialog={setShowBetDialog}
                setShowMarketDepthPopup={setShowMarketDepthPopup}
                handelBetFairPositions={handelBetFairPositions}
                profileData={
                  detail?.matchSetting?.length > 0
                    ? { ...detail, arrayCheck: "details" }
                    : { ...profileData, arrayCheck: "profile" }
                }
              />
            )}

          {detail?.eventName?.includes(" SRL ") || detail?.bookMaker == "off"
            ? ""
            : bookmaker?.length > 0 &&
              detail?.hasBookmaker &&
              detail?.bookMaker == "on" && (
                <Bookmaker
                  user={user}
                  detail={detail}
                  socketUrl={socketObj?.io?.uri}
                  displayType="bookmaker"
                  bookmakerSuspended={bookmakerSuspended}
                  bookmaker={bookmaker}
                  backOdds={bookmakerBackOdds}
                  layOdds={bookmakerLayOdds}
                  betSlipObject={betSlipObject}
                  showBetDialog={showBetDialog}
                  setLimit={setLimit}
                  scrollHeight={scrollHeight}
                  profileData={
                    detail?.matchSetting?.length > 0
                      ? { ...detail, arrayCheck: "details" }
                      : { ...profileData, arrayCheck: "profile" }
                  }
                  setBetSlipObject={setBetSlipObject}
                  setShowBetDialog={setShowBetDialog}
                />
              )}
          {detail?.gameType == "soccer" &&
            marketNew?.length > 0 &&
            marketNew?.map((res) => {
              return (
                <RunnerNew
                  parmas={parmas}
                  user={user}
                  res={res}
                  newType="multi"
                  betFairMs={!isEmpty(res) ? res?.ms : 3}
                  title={res?.market}
                  setRunner={setRunner}
                  detail={detail}
                  getMatchOdds={getMatchOdds}
                  getBetPosition={getBetPosition}
                  totalMatched={totalMatched}
                  runner={res?.jsonData}
                  market={marketNew}
                  backOdds={res?.back_odds?.length > 0 ? res?.back_odds : []}
                  layOdds={res?.lay_odds?.length > 0 ? res?.lay_odds : []}
                  setLimit={setLimit}
                  scrollHeight={scrollHeight}
                  setBetSlipObject={setBetSlipObject}
                  setShowBetDialog={setShowBetDialog}
                  setShowMarketDepthPopup={setShowMarketDepthPopup}
                  handelBetFairPositions={handelBetFairPositions}
                  profileData={
                    detail?.matchSetting?.length > 0
                      ? { ...detail, arrayCheck: "details" }
                      : { ...profileData, arrayCheck: "profile" }
                  }
                />
              );
            })}
          {detail?.eventType == 4 && detail?.hasFancy && (
            <div className="fancy-sport-tab p-2">
              <div className="fancy-sport-header">
                <div className="d-flex align-items-center taber">
                  {detail?.hasFancy &&
                    detail?.eventType == 4 &&
                    detail?.fancy == "on" &&
                    fancy?.length > 0 && (
                      <Button
                        className={fancySportTab === "fancy" && "active-taber"}
                        onClick={() => setFancySportTab("fancy")}
                      >
                        Fancybet
                      </Button>
                    )}

                  {!isEmpty(user) &&
                    moment(new Date()).isBefore(
                      moment(detail.eventDateTime).subtract(20, "m").toDate()
                    ) &&
                    detail?.eventType == 4 &&
                    detail?.hasFancy &&
                    detail?.marketCount == 2 && (
                      <Button
                        className={
                          fancySportTab === "lottery" && "active-taber"
                        }
                        onClick={() => setFancySportTab("lottery")}
                      >
                        Lottery
                      </Button>
                    )}
                </div>
              </div>
              {fancySportTab === "fancy" &&
                detail?.fancy == "on" &&
                fancy?.length > 0 && (
                  <FancyBet
                    user={user}
                    detail={detail}
                    handleShowInfo={handleShowInfo}
                    lineMarket={lineMarket}
                    data={fancyUpdated}
                    setLimit={setLimit}
                    position={fancyPosition}
                    socketUrl={socketObj?.io?.uri}
                    header={fancyHeader}
                    fancyHeaderKey={fancyHeaderKey}
                    tvClass={tvClass}
                    scrollHeight={scrollHeight}
                    handelChangeFancyHeader={handelChangeFancyHeader}
                    setShowPosition={setShowPosition}
                    profileData={
                      detail?.matchSetting?.length > 0
                        ? { ...detail, arrayCheck: "details" }
                        : { ...profileData, arrayCheck: "profile" }
                    }
                  />
                )}
              {fancySportTab === "lottery" &&
                !isEmpty(user) &&
                moment(new Date()).isBefore(
                  moment(detail.eventDateTime).subtract(20, "m").toDate()
                ) &&
                detail?.eventType == 4 &&
                detail?.hasFancy &&
                detail?.marketCount == 2 && <Lottery parmas={parmas} />}
            </div>
          )}
        </div>
        {<BottomNavigation />}
        {showInfo && (
          <Info handleShowInfo={handleShowInfo} sportRules={sportRules} />
        )}
        {showMarketDepthPopup && (
          <MarketDepth setShowMarketDepthPopup={setShowMarketDepthPopup} />
        )}
        {showMyBet && (
          <MyBets
            handleShowBet={handleShowBet}
            handleShowBet2={handleShowBet2}
          />
        )}{" "}
        {showMyBet2 && <MyBetsNew handleShowBet={handleShowBet2} />}
      </LayoutComponent>
    </div>
  );
};
export default FullMarket;
